.project-card {
	border-radius: 20px;
	height: 450px;
	margin-left: 25px;
	margin-right: 25px;
	box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	background-color: #b59a8e6a;
	transition: transform 0.5s ease;
}

.project-card:hover {
	transform: translate(0, -5px);
	box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.15);
}

.project-card-top {
	display: flex;
	justify-content: center;
	height: 35%;
}

.project-card-top img {
	border-radius: 8px;
	margin-top: 5%;
    max-width: 95%;
	height: 85%;
    aspect-ratio: 16 / 9;
}

.project-card-bottom {
	text-align: left;
	padding: 0 5%;
}

.project-links ul {
	justify-content: center;
	padding-inline-start: 0px;
	display: inline-flex;
	list-style: none;
	width: 100%;
}

.project-links ul li {
	margin-left: 20%;
	margin-right: 20%;
	font-size: 1.5em;
	transition: transform 0.1s ease;
}

.project-links ul li:hover {
	margin-left: 20%;
	margin-right: 20%;
	font-size: 1.5em;
	transform: scale(1.2);
}

.project-card-skills {
	color: #f5f5dc;
}

@media only screen and (max-width: 1024px) {
	.project-card-top img {
		border-radius: 8px;
		margin: 10% 0;
		max-width: 90%;
		height: 80%;
	}
    .project-card-bottom {
        margin-top: 10%;
        padding: 0 15%;
    }
	.project-card-bottom h1 {
		font-size: 22px;
	}
}

@media only screen and (max-width: 480px) {
	.project-card {
		height: 100%;
	}
	.project-card-top img {
		margin-top: 15%;
	}
    .project-card-bottom {
        margin-top: 1%;
    }
}
