.skill_tile {
	height: 50px;
	width: 50px;
	margin: 10px 25px;
	transition: transform 0.2s ease;
}

.skill_tile:hover {
	transform: scale(1.2);
	box-shadow: 0px 10px 8px -8px rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 1240px) {
	.skill_tile {
		height: 30px;
		width: 30px;
		margin: 10px 15px;
	}
}
